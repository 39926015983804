// For full documentation
// https://github.com/eBayClassifiedsGroup/react-advertising/wiki/Configuration#size-mappings

import { injectPositions } from '../../../../modules/interact-with-html'
import { getStoredItem } from '../../../../modules/stored-item'

const viewports = {
  mobile_s: [320, 0],
  all: [0, 0],
}

const pathsStickyMenu = [
  '/^/leagues|ligen|leghe$/',
  '/^/leagues|ligen|leghe/.[0-9]*/.[0-9]*/.*/.*/table|classifica|tabelle/',
  '/^/leagues|ligen|leghe/.[0-9]*/.[0-9]*/.*/.*/statistics|statistiche|statistik/',
  '/^/clubs/.[0-9]*/.*/table|classifica|tabelle/',
]

const pathsExcluded = ['/^/live/.[0-9]*/.[0-9]*/.*/.*/match|spiel|partita/.[0-9]*/.*/']

const fubas2Config = {
  viewports: viewports,
  sizeMappings: {
    S1: [
      {
        viewPortSize: viewports.all,
        sizes: [],
      },
      {
        viewPortSize: viewports.mobile_s,
        sizes: [
          [320, 100],
          [320, 50],
          [300, 100],
          [300, 50],
        ],
      },
    ],
  },

  // array of slot configurations
  slots: [
    {
      id: 'fubas2_s1',
      inject: {
        excludePaths: [...pathsStickyMenu, ...pathsExcluded],
        styleParent: 'display: flex; justify-content: center;',
        styleAd: 'margin-top: 15px; margin-bottom: 10px',
        selector: '#desktopWrapperContainer_Main',
        position: injectPositions.FIRST_OF_CHILD,
      },
      path: '/2273514/Fubas/fubas2_s1',
      sizeMappingName: 'S1',
    },
    {
      id: 'fubas2_s1',
      inject: {
        paths: pathsStickyMenu,
        styleParent: 'display: flex; justify-content: center; height: 120px',
        styleAd: 'padding-top: 15px; padding-bottom: 10px; position: fixed; z-index: 8; height: 120px; background-color: white; display: flex; justify-content: center',
        selector: '#desktopWrapperContainer_Main div',
        position: injectPositions.AFTER,
      },
      path: '/2273514/Fubas/fubas2_s1',
      sizeMappingName: 'S1',
    },
    {
      id: 'fubas2_s1',
      inject: {
        paths: ['/^/live/.[0-9]*/.[0-9]*/.*/.*/match|spiel|partita/.[0-9]*/.*/'],
        styleParent: 'display: flex; justify-content: center;',
        styleAd: 'margin-top: 15px; margin-bottom: 50px',
        selector: '#desktopWrapperContainer_Main div:last-of-type',
        position: injectPositions.AFTER,
      },
      path: '/2273514/Fubas/fubas2_s1',
      sizeMappingName: 'S1',
    },
  ],

  general: {
    disableInitialLoad: false,
    collapseEmptyDivs: true,
    enableSingleRequest: true,
    disableAddScrollbarWidth: true,
    userData: {
      adsExcludedLabels: () => {
        const user = getStoredItem('user', true)
        if (user) {
          return ['Programmatic']
        }
      },
    },
    adBlockingRecovery: {
      frequencyCap: {
        type: 'time',
        amount: 10,
      },
    },
    preloadedFiles: [
      {
        idLoadOnce: 'gpt-script',
        link: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
        async: true,
      },
    ],
    targeting: [
      {
        paths: ['/^/live/'],
        keyValues: {
          FUBAS2_seite: 'Live',
        },
      },
      {
        paths: ['/^/leagues|ligen|leghe/'],
        keyValues: {
          FUBAS2_seite: 'Liga',
        },
      },
      {
        paths: ['/^/clubs|club|vereine/'],
        keyValues: {
          FUBAS2_seite: 'Verein|vereine',
        },
      },
      {
        paths: ['/^/live/.[0-9]*/.[0-9]*/.*/.*/match|spiel|partita/.[0-9]*/.*/'],
        keyValues: {
          FUBAS2_seite: 'Spielansicht',
        },
      },
    ],
    customRulesUrls: [
      {
        paths: [
          '/^\/leagues|leghe|ligen\/.[0-9]*\/.[0-9]*\/.*\/.*/',
          '/^\/leagues|leghe|ligen\/.[0-9]*\/.[0-9]*\/.*\/.*/',
          '/^\/clubs|club|vereine\/.[0-9]*\/.*\/table|tabelle|classifica/',
          '/^\/leagues|leghe|ligen$/',
        ],
        customExclusionLabels: ['Programmatic', 'Standard'],
      },
      {
        url: 'profile',
        exact: false,
        noAds: true,
      }
    ],
  },
}

export default fubas2Config
