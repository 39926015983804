import { useUserContext } from '../../../ContextUser'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { addSpecialTargets } from '../../../../modules/ads-targets'
import Options from '../../../../config/options'
import prebidVideoSuccess from '../../../../bundles/BundleAds/vendor/prebid-magnite/modules/prebid-video-success'
import destroyAdSettings, { reloadPubstack } from '../../../../bundles/BundleAds/modules/destroy-ad-settings'
import ContextAds from '../../index'
import initAds from '../../../../bundles/BundleAds/modules/init-ads'
import { getStoredItem, setStoredItem } from '../../../../modules/stored-item'
import { get } from 'lodash'

const AdsProvider = ({ meta, targeting, wait, showAds, update, children, forcedConfigs, ...props }) => {
  const {
    access: { isPlus },
    profile,
  } = useUserContext()
  const reducer = (state, action) => {
    switch (action) {
      case 'wait':
        return 'wait'
      case 'ready':
      case 'default-start':
        return 'ready'
    }
  }

  const [adsState, adsDispatch] = useReducer(reducer, wait ? 'wait' : 'ready')
  const [targets, setTargets] = useState({})
  const [localVideoTargetUri, setLocalVideoTargetUri] = useState('')
  const [adsInitialized, setAdsInitialized] = useState(false)

  const metaUrl = meta?.url
  const locationUrl = props.location?.pathname

  useEffect(() => {
    if (locationUrl !== undefined) {
      setAdsInitialized(false)
    }
  }, [locationUrl])

  useEffect(() => {
    let isPlusFromStorage = getStoredItem('isPlus', true) ?? false
    let profileFromStorage = getStoredItem('user', true) ?? null

    if (isPlus !== undefined) {
      isPlusFromStorage = isPlus ? true : false
      setStoredItem('isPlus', isPlusFromStorage, true)
    }

    if (metaUrl && showAds && adsInitialized === false && adsState === 'ready') {
      setAdsInitialized(true)

      const mergedTargets = {
        ...meta.adsTargeting,
        ...targeting,
      }
      addSpecialTargets(mergedTargets)

      const checkPlus = isPlusFromStorage && profileFromStorage

      mergedTargets['splus'] = checkPlus ? 'true' : 'false' //magic default/premium
      mergedTargets['splus_flag'] = checkPlus ? 'true' : 'false'

      if (mergedTargets !== targets) {
        setTargets(mergedTargets)
      }
      let excludedLabels = []
      if (checkPlus) {
        excludedLabels = [...Options.Ads.sPlusExcludedAdsLabels, ...excludedLabels]
      }

      const onPrebidVideoSuccess = (props) => {
        const custParams = prebidVideoSuccess(props)
        setLocalVideoTargetUri(custParams)
      }

      initAds({
        adTargets: mergedTargets,
        excludedLabels: excludedLabels,
        //onPrebidVideoSuccess: onPrebidVideoSuccess,
        forcedConfigs,
      })
    }

    return () => {
      if (metaUrl && showAds && isPlus !== undefined && adsState === 'ready') {
        console.log('Reload Pubstack!')
        reloadPubstack()
      }
    }
  }, [metaUrl, isPlus, adsState, update, adsInitialized])

  const value = useMemo(() => ({ adsDispatch, targets, showAds: showAds, videoTargetUri: localVideoTargetUri }), [
    metaUrl,
    isPlus,
    adsState,
    targets,
    showAds,
    localVideoTargetUri,
  ])

  return (
    <ContextAds.Provider value={value} {...props}>
      {children}
    </ContextAds.Provider>
  )
}

export default AdsProvider
